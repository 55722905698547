




















import { Vue, Component, VModel, Prop } from 'vue-property-decorator'

@Component
export default class MeetingTypeSelector extends Vue {
  @VModel({
    required: true,
    type: Boolean,
  }) outside!: boolean

  @Prop({
    required: false,
    type: Boolean,
  }) disable!: boolean
}
